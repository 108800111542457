import React from "react"
import Img from "gatsby-image"
import {Button} from "@material-ui/core"
import {useStaticQuery,Link} from 'gatsby'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

import LayoutStyle from "../components/layout"
import SEO from "../components/seo"

const InnholdPage = () => {

  const data = useStaticQuery(graphql`
    query {
      
allSanityArticle(filter: { slug: { current: { ne: null } } },sort: {fields: slug___current,order: ASC}) {
        edges {
          node {
            title
            intro
            isFooter
            isMagasine
            isTips
            isInsurer
            isInsight
            isOfficial
            isAboutKasko
            slug {
              current
            }
            image_text
            image {
              asset {
                url
                _rawMetadata
              }
              crop{
                top
                left
                right
                bottom
              }
              hotspot{
                x
                y
              }
            }
        
          }
        }
      }
    
    
      file(relativePath: { eq: "kasko_mascot.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return(
    <LayoutStyle>
      <SEO title="Kasko.no: Oversikt over innhold" />

      <Jumbotron>
        <Container>
          <h1>Oversikt over innhold på kasko.no</h1>
          <p>
            Under er en kategorisert oversikt over innholdet på kasko.no
          </p>
        </Container>
      </Jumbotron>

      <h2>Tips og triks:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isTips){
            return(
              <li key={'tt'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

      <h2>Magasinet:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isMagasine){
            return(
              <li key={'ma'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

      <h2>Markedsinnsikt:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isInsight){
            return(
              <li key={'m'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

      <h2>Forsikringsselskaper:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isInsurer){
            return(
              <li key={'f'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

      <h2>Om Kasko.no:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isAboutKasko){
            return(
              <li key={'o'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

      <h2>Informasjon:</h2>
      <ul>
        {data.allSanityArticle.edges.map(({node: article},i) => {
          if(article.isOfficial){
            return(
              <li key={'a'+i}><Link to={'/'+article.slug.current}>{article.title}</Link></li>
            )
          }
        })}
      </ul>

    </LayoutStyle>
  )
}

export default InnholdPage


